@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto+Mono&display=swap");
html {
  scroll-behavior: smooth;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-image: url("https://www.transparenttextures.com/patterns/cubes.png");
}
